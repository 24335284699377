var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main-layout',{staticClass:"padded",attrs:{"is-loading":_vm.loadingState.page}},[_c('div',{staticClass:"pvp-container"},[_c('div',{staticClass:"ratings-container"},[_c('h2',{staticClass:"ratings-title"},[_vm._v(_vm._s(_vm.$t('ratings.few')))]),_c('section',{staticClass:"games-filter-section"},[_c('games-filter',{on:{"input":_vm.gamesFilterInputHandler},model:{value:(_vm.gamesIds),callback:function ($$v) {_vm.gamesIds=$$v},expression:"gamesIds"}},[_c('template',{slot:"title"},[_vm._v(_vm._s(_vm.$t('games.filter')))])],2)],1),_c('div',{staticClass:"ratings-container__tab-list"},[_c('TabList',{attrs:{"tabs":_vm.tabs},on:{"change-tab":_vm.tabListChangeHandler}})],1),(_vm.loadingState.ratingsPage)?[_c('div',{staticClass:"loader-container"},[_c('loader')],1)]:[(_vm.currentTabIndex === 0)?[(_vm.ratings.length)?[_c('div',{staticClass:"ratings-table-list"},_vm._l((_vm.ratings),function(rating){return _c('div',{key:rating.id,staticClass:"ratings-table-container"},[_c('h4',{staticClass:"rating-table__title"},[_c('BaseLink',{attrs:{"to":{
                      name: 'rating',
                      params: {
                        id: rating.id,
                        version: rating.version,
                      },
                    }},on:{"click-native":function($event){return _vm.ratingClickHandler(rating.name)}}},[_vm._v(" "+_vm._s(rating.name)+" ")])],1),_c('RatingTable',{attrs:{"rating":rating}}),_c('div',{staticClass:"ratings-container__button"},[_c('pvp-btn',{staticClass:"about-rating-button",attrs:{"to":{
                      name: 'rating',
                      params: {
                        id: rating.id,
                        version: rating.version,
                      },
                    },"block":true,"variant":"secondary"},on:{"click-native":function($event){return _vm.ratingClickHandler(rating.name)}}},[_vm._v(_vm._s(_vm.$t('ratings.more'))+" ")])],1)],1)}),0)]:_c('div',{staticClass:"rating-placeholder"},[_c('div',{staticClass:"placeholder-icon"},[_c('icon',{attrs:{"name":"pvp-swords"}})],1),_c('div',{staticClass:"placeholder-text"},[_vm._v(" "+_vm._s(_vm.$t('ratings.placeholderByGames'))+" ")])]),(_vm.showPagination)?_c('pvp-pagination',{attrs:{"params":_vm.paginationParams,"disabled":_vm.loadingState.ratingsPage},on:{"onchange":_vm.setPage}}):_vm._e()]:[_c('UserRatings',{attrs:{"games-ids":_vm.gamesIds,"profile-hash":_vm.getCurrentProfileHash}})]]],2)]),_c('template',{slot:"seo-text"},[_c('p',[_vm._v(_vm._s(_vm.seoText))])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }