<template>
  <main-layout :is-loading="isLoading" class="padded">
    <div class="pvp-container">
      <div class="ratings-container">
        <BaseLink :to="{ name: 'ratings' }" class="rating-link">
          <Icon class="arrow" name="arrow-left" inline />
          {{ $t('ratings.page_toRatingsList') }}
        </BaseLink>
        <div class="rating-headline">
          <h2 class="rating-title">{{ $t('ratings.single') }}</h2>
          <BaseSocialLinks
            class="rating-socials"
            :show-title="false"
            :share-title="shareTitle"
          />
        </div>
        <loader v-if="isLoading" />
        <template v-else>
          <div class="rating-table__title"></div>
          <headline class="centered">
            <h2 slot="title">
              {{ rating.name }}
            </h2>
            <template v-if="rating.description">
              <Icon
                slot="addon"
                name="info"
                class="information-icon"
                inline
              />
              <div slot="addon">
                <pvp-btn
                  size="sm"
                  variant="link"
                  @click="descriptionModalOpened = true"
                  >{{ $t('ratings.page_detail') }}
                </pvp-btn>
              </div>
            </template>
          </headline>
          <RatingFullTable :rating="rating" />
        </template>
        <pvp-pagination
          v-if="showPagination"
          :params="paginationParams"
          :disabled="isLoading"
          @onchange="setPage"
        />
      </div>
    </div>

    <pvp-modal
      v-if="rating.description"
      v-model="descriptionModalOpened"
      width="500"
    >
      <div slot="modal-title">{{ $t('ratings.page_detail') }}</div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="modal-rating-description"
        v-html="rating.description"
      ></div>
      <template slot="modal-footer">
        <pvp-btn
          variant="secondary"
          @click="descriptionModalOpened = false"
          >{{ $t('global.close') }}
        </pvp-btn>
      </template>
    </pvp-modal>

    <template slot="seo-text">
      <p>{{ seoText }}</p>
    </template>
  </main-layout>
</template>

<script>
import RatingFullTable from '@components/RatingComponents/RatingFullTable.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseSocialLinks from '@components/BaseComponents/BaseSocialLinks.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'RatingRegularPage',
  components: {
    RatingFullTable,
    Icon,
    BaseLink,
    BaseSocialLinks,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    rating: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      descriptionModalOpened: false,
      // Pagination
      currentPage: 1,
      perPage: 12,
      total: 0,
      perPageSizes: [12, 36, 72],
    };
  },
  page() {
    return {
      title: this.title,
    };
  },
  computed: {
    seoText() {
      return this.$t('seo.ratingPage', {
        host: window.location.host.toLocaleUpperCase(),
      });
    },
    title() {
      return this.$t('ratings.meta_title', {
        name: _.get(this.rating, 'name'),
      });
    },

    paginationParams() {
      return {
        page: this.currentPage,
        perPage: this.perPage,
        total: this.rating?.total,
        perPageSizes: this.perPageSizes,
      };
    },

    shareTitle() {
      return this.$t('ratings.share', {
        name: this.rating.name,
        host: window.location.host.toLocaleUpperCase(),
      });
    },

    showPagination() {
      return this.rating.total > 12;
    },
  },
  watch: {
    perPage: {
      handler: 'reloadRating',
    },
    currentPage: {
      handler: 'reloadRating',
    },
  },
  methods: {
    setPage({ page, perPage }) {
      this.perPage = perPage;
      this.currentPage = page;
    },

    reloadRating() {
      this.$emit('reloadRating', {
        pageNum: this.currentPage,
        pageSize: this.perPage,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.rating-link {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  @include max-tablet() {
    margin-bottom: 10px;
  }

  .arrow {
    margin-right: 4px;
    color: $azure;
  }

  &:hover {
    text-decoration: none;

    .arrow {
      color: $dodger-blue;
    }
  }
}

.rating-title {
  margin-bottom: 40px;
  font-size: 34px;
  font-weight: bold;
  @include max-tablet() {
    margin-bottom: 30px;
    font-size: 32px;
  }
}

.rating-table {
  @include max-tablet() {
    margin-top: 20px;
  }
}

.rating-table__title {
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  @include max-tablet() {
    font-size: 18px;
  }
}

.pagination {
  margin-top: 30px;
}

.rating-headline {
  display: flex;
  justify-content: space-between;
  @include max-laptop() {
    flex-direction: column;
    .rating-socials {
      order: 1;
      margin-bottom: 20px;
    }
    .rating-title {
      order: 2;
    }
  }
}

.information-icon {
  color: rgba(white, 0.5);
  font-size: 20px;
  line-height: 20px;
  margin-right: 10px;
}
</style>
