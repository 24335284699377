<template>
  <component
    :is="ratingComponent"
    :id="id"
    :is-loading="isLoading"
    :rating="rating"
    @reloadRating="reloadRating"
  />
</template>

<script>
import RatingRegularPage from './RatingRegularPage.vue';
import RatingAdvancedPage from './RatingAdvancedPage.vue';
import { fetchRatingById } from '@src/shared/api/rating';

export default {
  name: 'Rating',
  components: {
    RatingRegularPage,
    RatingAdvancedPage,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    version: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rating: {},
      isLoading: false,
    };
  },
  computed: {
    ratingComponent() {
      switch (this.version || this.rating.version) {
        case 'regular':
          return 'RatingRegularPage';
        case 'advanced':
          return 'RatingAdvancedPage';
        default:
          return 'RatingRegularPage';
      }
    },
  },
  mounted() {
    this.loadRatingItem();
  },
  methods: {
    reloadRating(paginationData) {
      return this.loadRatingItem(paginationData);
    },

    async loadRatingItem(
      { pageNum, pageSize } = { pageNum: 1, pageSize: 12 },
    ) {
      this.isLoading = true;
      const responseData = await fetchRatingById({
        pageNum,
        pageSize,
        id: this.id,
      });

      this.rating = {
        ...responseData.rating,
        items: responseData.items,
        structure: responseData.structure,
        total: responseData.pagen.totalCount,
      };
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
