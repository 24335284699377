<template>
  <Table>
    <template #thead>
      <TableRow>
        <TableData :heading="true" text-align="left">
          {{ $t('ratings.table_ratingName') }}
        </TableData>
        <TableData :heading="true">{{
          $t('places.single')
        }}</TableData>
        <TableData
          v-if="needTeamInfo"
          :heading="true"
          text-align="left"
        >
          {{ $t('ratings.table_team') }}
        </TableData>
        <TableData :heading="true">{{
          $t('ratings.single')
        }}</TableData>
      </TableRow>
    </template>
    <template v-if="list.length" #tbody>
      <TableRow v-for="(item, index) in list" :key="index">
        <TableData text-align="left">
          <BaseLink
            :to="{
              name: 'rating',
              params: {
                id: item?.idRating,
                version: item?.version,
              },
            }"
          >
            {{ item.name }}
          </BaseLink>
        </TableData>
        <TableData>{{ item.place }}</TableData>
        <TableData
          v-if="needTeamInfo && item.teamHash"
          text-align="left"
        >
          <BaseLink
            :to="{ name: 'team', params: { hash: item.teamHash } }"
            class="ratings-list-table__team"
          >
            <team-avatar
              :inline="false"
              :src="item.image"
              class="ratings-list-table__team-icon"
            />
            {{ item.teamName }}
          </BaseLink>
        </TableData>
        <TableData>{{ item.finedValue }}</TableData>
      </TableRow>
    </template>
  </Table>
</template>

<script>
import Table from '@components/v2/ui/Table.vue';
import TableRow from '@components/v2/ui/TableRow.vue';
import TableData from '@components/v2/ui/TableData.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'RatingsListTable',
  components: {
    Table,
    TableRow,
    TableData,
    BaseLink,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    needTeamInfo: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.ratings-list-table__team,
.ratings-list-table__team:hover {
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
}
.ratings-list-table__team-icon {
  width: 32px;
  height: 32px;
  margin-right: 1rem;
}
</style>
