var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Table',{scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('TableRow',[_c('TableData',{attrs:{"heading":true,"text-align":"left"}},[_vm._v(" "+_vm._s(_vm.$t('ratings.table_ratingName'))+" ")]),_c('TableData',{attrs:{"heading":true}},[_vm._v(_vm._s(_vm.$t('places.single')))]),(_vm.needTeamInfo)?_c('TableData',{attrs:{"heading":true,"text-align":"left"}},[_vm._v(" "+_vm._s(_vm.$t('ratings.table_team'))+" ")]):_vm._e(),_c('TableData',{attrs:{"heading":true}},[_vm._v(_vm._s(_vm.$t('ratings.single')))])],1)]},proxy:true},(_vm.list.length)?{key:"tbody",fn:function(){return _vm._l((_vm.list),function(item,index){return _c('TableRow',{key:index},[_c('TableData',{attrs:{"text-align":"left"}},[_c('BaseLink',{attrs:{"to":{
            name: 'rating',
            params: {
              id: item?.idRating,
              version: item?.version,
            },
          }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('TableData',[_vm._v(_vm._s(item.place))]),(_vm.needTeamInfo && item.teamHash)?_c('TableData',{attrs:{"text-align":"left"}},[_c('BaseLink',{staticClass:"ratings-list-table__team",attrs:{"to":{ name: 'team', params: { hash: item.teamHash } }}},[_c('team-avatar',{staticClass:"ratings-list-table__team-icon",attrs:{"inline":false,"src":item.image}}),_vm._v(" "+_vm._s(item.teamName)+" ")],1)],1):_vm._e(),_c('TableData',[_vm._v(_vm._s(item.finedValue))])],1)})},proxy:true}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }